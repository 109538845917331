import React from "react";
import BuyStyle from './BuyJournal.module.scss';
import ButtonNavigation from "../ButtonNavigation/ButtonNavigation";
import {Helmet} from "react-helmet";

const BuyJournal = () => {
    const isMobile = window.innerWidth > 768;
    return (
        <>
            <Helmet>
                <title>Gambler: Розкриваючи Таємниці Гемблінгу в Україні</title>
                <meta name="description" content="Ласкаво просимо до 'Gambler' – вашого ключового джерела новин та ексклюзивних матеріалів щодо гемблінгу в Україні. В нашому журналі ви знайдете ексклюзивні інтерв'ю, ретельну аналітику та головні інсайти сфери, що допоможуть вам залишатися на передовій в світі гри та розваг. Зануртесь у світ азартних розваг разом з 'Gambler'."/>
            </Helmet>

            <div className={BuyStyle.PageContainer}>
                {!isMobile && <ButtonNavigation pageActive={'buy'} />}
                {!isMobile && <>
                    <h1 className={`${BuyStyle.TitlePage} ${BuyStyle.Buy}`}>Адреса магазинів</h1>
                    <h2 className={BuyStyle.Subtitle}>
                        <span>Придбати журнал можливо у мережах магазинів Сільпо та NOVUS за наступними адресами:</span>
                    </h2>
                </>}
                <div className={BuyStyle.RightSide}>
                    {isMobile && <>
                        <h1 className={`${BuyStyle.TitlePage} ${BuyStyle.Buy}`}>Адреса магазинів</h1>
                        <h2 className={BuyStyle.Subtitle}>
                            <span>Придбати журнал можливо у мережах магазинів Сільпо та NOVUS за наступними адресами:</span>
                        </h2>
                    </>}

                    <div className={`${BuyStyle.Banner} ${BuyStyle.MapScreen}`}>
                        <iframe
                            src="https://storage.googleapis.com/maps-solutions-mujyl7tvnk/locator-plus/5gvc/locator-plus.html"
                            width="100%" height="100%"
                            style={{border: 0}}
                            loading="lazy">
                        </iframe>
                    </div>
                    {/*{!isMobile && <ListAdress/>}*/}
                </div>
            </div>
        </>
    )
}

export default BuyJournal;