import React from "react";
import BorderAge from '../../assets/images/border-age.png';
import Logo from '../../assets/images/logo.png';
import BgFooter from '../../assets/images/BG-Footer.png';
import FooterStyle from './FooterStyle.module.scss';

const Footer = () => {
    return (
        <footer className={FooterStyle.footer}>
            <img src={BgFooter} className={FooterStyle.footer_bg} alt=""/>
            <div className={FooterStyle.PCDesign}>
                <div className={FooterStyle.Description}>
                    <div className={FooterStyle.Logo_AgeSuccess}>
                        <div className={FooterStyle.AgeSuccess}>
                            <img src={BorderAge} className={FooterStyle.border} alt=""/>
                            <span>21+</span>
                        </div>

                        <div className={FooterStyle.Mobile_Logo}>
                            <img src={Logo} alt=""/>
                        </div>
                    </div>

                    <div className={FooterStyle.TextFooter}>
                        <p className={FooterStyle.Text}>
                            Журнал Gambler, сайт <a href="/">https://gamblerua.com</a> та
                            телеграм-канал <a
                            href="https://t.me/uagambler">https://t.me/uagambler</a> не операторами азартних ігор, а є
                            інформаційними ресурсами про легальний гральний бізнес в Україні. Всі матеріали публікуються
                            в інформаційних цілях.
                        </p>
                    </div>
                </div>
                <div className={FooterStyle.FooterLogo}>
                    <img src={Logo} alt=""/>
                </div>
            </div>
        </footer>
    )
}

export default Footer;