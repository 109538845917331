import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import BtnNavStyle from './ButtonNavStyle.module.scss';

const ButtonNavigation = () => {
    const location = useLocation();

    return (
        <div className={BtnNavStyle.ButtonsNavigationContainer}>
            <div className={`${BtnNavStyle.Button} ${location.pathname === '/' ? BtnNavStyle.active_page : ''}`}>
                <NavLink to="/" className={BtnNavStyle.Button_link}>
                    Про журнал
                </NavLink>
            </div>
            <div className={`${BtnNavStyle.Button} ${location.pathname === '/buy' ? BtnNavStyle.active_page : ''}`}>
                <NavLink to="/buy" className={BtnNavStyle.Button_link}>
                    Де купити
                </NavLink>
            </div>
        </div>
    );
};

export default ButtonNavigation;
