import React from "react";
import Logo from '../../assets/images/logo.png';
import TG_icon from '../../assets/images/tg-icon.png';
import TG_icon_black from '../../assets/images/icon-tg_white.png';
import ButtonNavigation from "../ButtonNavigation/ButtonNavigation";
import HeaderStyle from './HeaderStyle.module.scss'

const Header = () => {
    const isPc = window.innerWidth > 768;
    return (
        <header className={`${HeaderStyle.Header} containerComponents`}>
            <div className={HeaderStyle.logo}>
                <img src={Logo} alt="Logo" />
            </div>
            <div className={HeaderStyle.NavigateBlockHeader}>
                {!isPc ? '' : <ButtonNavigation />}

                <div className={HeaderStyle.Telegram}>
                    <a href="https://t.me/+U5axIhoDFjgzYTcy" target="_blank" className={HeaderStyle.Telegram_link}>
                        Telegram канал
                        <img src={TG_icon} alt="" className={HeaderStyle.icon_white}/>
                        <img src={TG_icon_black} alt="" className={HeaderStyle.icon_black}/>
                    </a>
                </div>
            </div>
        </header>
    );
}

export default Header;