import React from "react";
import TestPCBg from '../../assets/images/banner-pc_test.png';
import BannerMobile from '../../assets/images/banner_mob.png';
import NewBanner from '../../assets/images/new_banner.jpeg';
import AboutStyle from './PageAboutStyle.module.scss';
import { Helmet } from 'react-helmet';
import ButtonNavigation from "../ButtonNavigation/ButtonNavigation";

const PageAbout = () => {
    const isMobile = window.innerWidth > 768;
    return (
        <>
            <Helmet>
                <title>Gambler: Розкриваючи Таємниці Гемблінгу в Україні</title>
                <meta name="description" content="Ласкаво просимо до 'Gambler' – вашого ключового джерела новин та ексклюзивних матеріалів щодо гемблінгу в Україні. В нашому журналі ви знайдете ексклюзивні інтерв'ю, ретельну аналітику та головні інсайти сфери, що допоможуть вам залишатися на передовій в світі гри та розваг. Зануртесь у світ азартних розваг разом з 'Gambler'."/>
            </Helmet>
            <div className={AboutStyle.PageContainer}>
                <div className={AboutStyle.LeftSide}>
                    <h1 className={AboutStyle.TitlePage}>Гра починається</h1>
                    <h2 className={AboutStyle.Subtitle}>
                        <span>Ласкаво просимо у світ нового журналу "Gambler" – спеціалізованого видання про гемблінг в Україні, яке розкриває найактуальніші новини та події в галузі. </span>
                    </h2>
                    <div className={AboutStyle.StatisticsList}>
                        <p className={AboutStyle.StatisticsItem}>Інтерв'ю з головою КРАІЛ</p>
                        <p className={AboutStyle.StatisticsItem}>Огляд українського IGAMING ринку</p>
                        <p className={AboutStyle.StatisticsItem}>Важливі прогнози та інсайти для гравців та учасників ринку</p>
                        <p className={AboutStyle.StatisticsItem}>Тренди сфери на 2024 рік</p>
                        <p className={AboutStyle.StatisticsItem}>Відомості, які розкриють аспекти галузі з перших вуст</p>
                    </div>
                    {!isMobile && <ButtonNavigation pageActive={'about'}/>}

                    <div className={AboutStyle.ButtonBlock}>
                        <div className={AboutStyle.Button}><a href="https://t.me/gambler_purchase_bot" target="_blank"><span>Купити PDF</span></a></div>
                        <div className={AboutStyle.Button}><a
                            href="https://t.me/+U5axIhoDFjgzYTcy"><span>Telegram канал</span></a></div>
                    </div>
                </div>
                <div className={`${AboutStyle.RightSide} ${AboutStyle.PageAbout}`}>
                    <div className={AboutStyle.Banner}>
                        <img src={NewBanner} className={AboutStyle.BannerImage} alt=""/>
                    </div>

                    <div className={AboutStyle.MobileButtonBuy}>
                        <p className={AboutStyle.ContainerButton}>
                            <a href="https://t.me/gambler_purchase_bot" target="_blank">Купити PDF</a>
                        </p>
                    </div>
                    <div className={AboutStyle.TextBlock}>
                        <div className={AboutStyle.DesctopContent}>
                            <div className={AboutStyle.TextBlockList}>
                                <ul className={AboutStyle.ListAboutText}>
                                    <li className={AboutStyle.ListAboutText_Item}>Розбір ринку affiliate-маркетингу в iGaming у 24му</li>
                                    <li className={AboutStyle.ListAboutText_Item}>Ексклюзивні інтервʼю (КРАІЛ, Affise, Pin-Up)</li>
                                    <li className={AboutStyle.ListAboutText_Item}>ТОП кращих ігор в українських онлайн казино</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PageAbout;