import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Header from './components/Header/Header';
import PageAbout from "./components/PageAbout/PageAbout";
import Footer from "./components/Footer/Footer";
import BuyJournal from "./components/BuyJournal/BuyJournal";
import './assets/style/app.css';
import './assets/fonts/fonts.css';

function App() {
    return (
        <>
        <Router>
            <Header />
            <Routes>
                <Route path="/" exact element={<PageAbout />} />
                <Route path="/buy" element={<BuyJournal />} />
            </Routes>
            <Footer />
        </Router>
        </>
    );
}

export default App;
